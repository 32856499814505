<template>
  <travio-modal width='700' height="450" zIndex="99999">
    <h3 slot="header">{{title}}</h3>
    <div slot="body">
      <div class="vx-row location-list-autocomplete">
        <div class="vx-col w-full" v-if="!noSave">
          <label class="">Location Name / Alias (this is what is displayed to the customer)</label>
          <vs-input class="w-full" v-model="locationState.locationName" v-validate="'required|max:100'" name="Location Name" />
          <span class="text-danger text-sm">{{ errors.first('Location Name') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Search Location (search for an item from the autocomplete)</label>
          <v-autocomplete 
            :items="items"
            v-model="itemInput"
            :get-label="getLabel"
            :component-item='itemTemplate' 
            @update-items="updateItems" 
            @input="updateSettings">
          </v-autocomplete>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveLocation" type="filled">Save</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import LocationEditorAutoCompleteItem from './LocationEditorAutoCompleteItem'
export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      itemInput: {},
      items: [],
      itemTemplate: LocationEditorAutoCompleteItem,
      locationState: {}
    }
  },
  props: {
    applicationId: { required: true }, 
    location: { type: Object, required: true },
    noSave: { type: Boolean, default: false }, // True if used by Extra Editor or Skyscanner
    geographyType: { type: Number, default: null }
  },
  computed: {
    title () {
      if(this.noSave) {
        return 'Select Event Location'
      }
      return this.locationState.locationId ? 'Edit Searchable Location' : 'Add Searchable Location'
    }
  },
  mounted (){
    this.locationState = JSON.parse(JSON.stringify(this.location))
    if (this.locationState.jsonData) {
      this.itemInput = JSON.parse(this.locationState.jsonData)
    }
  },
  methods: {
    updateSettings (value) {
      const shouldReplaceName = !this.locationState.locationId || !this.locationState.locationName
      if(value && value.name && shouldReplaceName) {
        let countryPostFix = value.countryCode ? `, ${value.countryCode}` : ""
        this.locationState.locationName = value.name + countryPostFix
      }
      this.locationState.jsonData = JSON.stringify(value)
    },
    getLabel (item) {
      let countryPostFix = item.countryCode ? `, ${item.countryCode}` : ""
      return (item && item.name) ? item.name + countryPostFix : null
    },
    updateItems (text) {
      let query = `https://api.travelify.io/autocomplete?query=${text}`
      if(this.geographyType) {
        query = `https://api.travelify.io/autocomplete?geographytype=${this.geographyType}&query=${text}`
      }
      fetch(query)
      .then(res => res.json())
      .then(res => {
          if (res) {
            this.items = res
          }
      })
      .catch(err => {
          console.log(err)
      })
    },
    clearSelection () {
      this.itemInput = {}
    },
    async saveLocation () {

      // Only used by Extra Editor
      if(this.noSave) {
        this.$emit('onEditSuccess', this.locationState.jsonData)
        return
      }

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      if(!this.locationState.jsonData) {
        this.$_notifyFailure('Invalid form data. Please select a valid location')
        return
      }
      if (!this.itemInput){
        this.$_notifyFailure("Please select a location")
        return
      }
      
        this.$vs.loading()

      let emitter = null
      let promise = null

      if(this.locationState.locationId) {
        emitter = 'onEditSuccess'
        promise = this.$http.put(`api/locationLists/apps/${this.applicationId}/${this.location.locationListId}`, this.locationState)
      } else {
        emitter = 'onAddSuccess'
        promise = this.$http.post(`api/locationLists/apps/${this.applicationId}/${this.location.locationListId}`, this.locationState)
      }

      try {
        let response = await promise
        this.$_notifySuccess('Successfully saved location data');

        if(!this.locationState.locationId){
          this.locationState.locationId= response.data
        }
        this.$emit( emitter, Object.assign(this.locationState) )
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  

      
      
    },
    close () {
      this.showLocationNameEditor=false
      this.$emit('onCancel')
    }
  }
}
</script>

<style>
.location-list-autocomplete input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.location-list-autocomplete input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}

.location-list-autocomplete .v-autocomplete-input {
  padding: .7rem;
  font-size: 1rem !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 5px;
  font-family: inherit;
  color:inherit;
  width: 100%;
}

.location-list-autocomplete .v-autocomplete-list {
  width:100%;
} 

.location-list-autocomplete .feather-icon {
  /* Fix to icon overlapping search results */
  position: inherit !important;
}
</style>
