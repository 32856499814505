<template>
  <div class="location-autocomplete-item p-3">

    <div class="flex items-center justify-start">
      <div class="px-2 mr-2" v-if="item && item.name">
        <feather-icon icon="MapIcon" svg-classes="w-7 h-7"/>
      </div>
      <div>
        <div>{{ item && item.name }}, {{ item && item.countryCode }}</div>
        <div class="text-sm text-muted">{{item && item.type}}</div>
      </div>
    </div>
  </div>

  
</template>
 
<script>
export default {
  props: {
    item: { required: true },
  }
}
</script> 

<style>
.location-autocomplete-item {
  background-color: white;
  width: 100%;
}

</style>
